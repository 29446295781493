import React from 'react';
import Image from 'next/image'
import { FaCheck } from 'react-icons/fa';
import Header from '../components/Header'
import Footer from '../components/Footer'
import Head from 'next/head';
import { server } from '../config/index'
import ScoreComparison from '../components/ScoreComparison';
import Faq from '../components/FAQ/Faq';

export function Stats() {
  return (
    <div>
      <h3 className='text-blue-900 text-2xl sm:text-3xl text-center py-5 mt-5 uppercase'>Our Features</h3>
      <div className="flex justify-center items-center text-white">
        <div className="grid grid-cols-2 md:flex md:flex-row pt-5 pb-6 px-4 md:px-12 gap-4 md:gap-8 bg-gradient-to-r bg-[#3A418D] rounded-lg">
          <div className="flex flex-col items-center py-4">
            <p className="font-bold uppercase text-3xl sm:text-4xl">
              22K+
            </p>
            <p className="text-lg sm:text-xl font-normal text-center uppercase mt-2">
              people&nbsp;
              <br className="hidden md:block" />
              CHOSE US
            </p>
          </div>
          <div className="flex flex-col items-center py-4">
            <p className="font-bold uppercase text-3xl sm:text-4xl">
              100K+
            </p>
            <p className="text-lg sm:text-xl font-normal text-center uppercase mt-2">
              essays&nbsp;
              <br className="hidden md:block" />
              checked
            </p>
          </div>
          <div className="flex flex-col items-center py-4">
            <p className="font-bold uppercase text-3xl sm:text-4xl">
              137
            </p>
            <p className="text-lg sm:text-xl font-normal text-center uppercase mt-2">
              PRO&nbsp;
              <br className="hidden md:block" />
              USERS
            </p>
          </div>
          <div className="flex flex-col items-center py-4">
            <p className="font-bold uppercase text-3xl sm:text-4xl">
              7.5
            </p>
            <p className="text-lg sm:text-xl font-normal text-center uppercase mt-2">
              AVERAGE&nbsp;
              <br className="hidden md:block" />
              SCORE
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function TargetAudience() {
  return (
    <div className="pb-12">
      <h3 className="text-blue-900 text-2xl sm:text-3xl text-center py-5 mt-5 uppercase">
        Who can benefit from our platform?
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-3xl mx-auto px-4">
        <div className="p-4 shadow-md rounded-md">
          <h4 className="text-xl font-bold mb-4">Students & Professionals</h4>
          <ul className="list-disc list-outside ml-4 text-gray-600">
            <li>
              High school graduates from countries like Bangladesh, China, India, and others aiming to study abroad in the US, UK, Germany, Canada, and Australia.
            </li>
            <li>
              Job seekers and professionals looking to relocate to English-speaking countries or enhance their career opportunities.
            </li>
          </ul>
        </div>
        <div className="p-4 shadow-md rounded-md">
          <h4 className="text-xl font-bold mb-4">Teachers & Institutions</h4>
          <ul className="list-disc list-outside ml-4 text-gray-600">
            <li>
              English and IELTS preparation teachers who want to provide cutting-edge self-evaluation and self-study tools for their students.
            </li>
            <li>
              Language schools, tutoring centers, and universities looking to upgrade their IELTS preparation curriculum with an AI-powered solution.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}



const LandingPage = ({ }) => {
  return (

    <div className="flex min-h-screen flex-col items-center justify-center">
      <Head>
        <title>
          AI-Powered IELTS Writing Assistant | Improve Your Score with Mock Tests & Instant Feedback
        </title>
        <meta
          name="description"
          content="Maximize your IELTS writing score with our AI-powered education assistant. Get instant feedback, personalized suggestions, and practice with mock tests for Task 2, designed to optimize your performance."
        />
        <meta property="og:url" content={`${server}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AI-Powered IELTS Writing Assistant | Improve Your Score with Mock Tests & Instant Feedback" />
        <meta property="og:description" content="Maximize your IELTS writing score with our AI-powered education assistant. Get instant feedback, personalized suggestions, and practice with mock tests for Task 2, designed to optimize your performance." />
        <meta property="og:image" content={`${server}/home.jpg`} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content={`${server}`} />
        <meta property="twitter:url" content={`${server}`} />
        <meta name="twitter:title" content="AI-Powered IELTS Writing Assistant | Improve Your Score with Mock Tests & Instant Feedback" />
        <meta name="twitter:description" content="Maximize your IELTS writing score with our AI-powered education assistant. Get instant feedback, personalized suggestions, and practice with mock tests for Task 2, designed to optimize your performance." />
        <meta name="twitter:image" content={`${server}/home.jpg`} />
      </Head>

      <Header />


      <div className="w-full bg-center bg-cover md:bg-contain bg-[#3A418D]">
        <div className="max-w-3xl flex flex-col-reverse md:flex-row items-center m-auto pt-5">
          <div className="w-full md:w-1/2 h-48 md:h-full">
            <Image
              className="object-cover object-top h-full w-full max-w-md"
              src="/happy-student.png"
              alt="Happy student"
              width="586"
              height="441"
              loading='eager'
              priority
            />
          </div>
          <div className="w-full md:w-1/2 max-w-3xl px-4 py-5">
            <h2 className="text-3xl font-bold mb-2 text-white">
              Crush the writing section of the IELTS exam
            </h2>
            <p className="text-white ">
              Our AI-powered platform specializes in IELTS Writing Task 2 preparation, providing top-quality, instant, and personalized results.
            </p>
            <a
              href="/trainer"
              className="bg-green-500 text-white font-semibold py-1 px-4 mt-5 inline-block rounded text-lg hover:bg-green-600"
            >
              Try now free
            </a>
          </div>
        </div>
      </div>


      <Stats />

      <div className="flex flex-col justify-center items-center pt-5 pb-0 mx-auto p-4 w-full max-w-3xl">

        <div className="flex flex-col justify-center items-center pt-12 mx-auto p-4 w-full max-w-3xl">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-12">
            <div className="bg-white p-3 shadow-md border-2 border-[#A1A9C3] rounded-md flex items-center">
              <FaCheck className="text-green-500 mr-2" />
              <p className="text-[#474747] text-sm">Instant feedback and scoring</p>
            </div>
            <div className="bg-white p-3 shadow-md border-2 border-[#A1A9C3] rounded-md flex items-center">
              <FaCheck className="text-green-500 mr-2" />
              <p className="text-[#474747] text-sm">Deep analysis and rewrites</p>
            </div>
            <div className="bg-white p-3 shadow-md border-2 border-[#A1A9C3] rounded-md flex items-center">
              <FaCheck className="text-green-500 mr-2" />
              <p className="text-[#474747] text-sm">30 Writing Task 2 topics</p>
            </div>
            <div className="bg-white p-3 shadow-md border-2 border-[#A1A9C3] rounded-md flex items-center">
              <FaCheck className="text-green-500 mr-2" />
              <p className="text-[#474747] text-sm">Quote-by-quote feedback</p>
            </div>
            <div className="bg-white p-3 shadow-md border-2 border-[#A1A9C3] rounded-md flex items-center">
              <FaCheck className="text-green-500 mr-2" />
              <p className="text-[#474747] text-sm">Customized improvement suggestions</p>
            </div>
          </div>
        </div>

        <TargetAudience />

        <h3 className="text-blue-900 text-2xl sm:text-3xl text-center py-5 mt-5 uppercase">
          Why Choose Us?
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <div className=" p-4 shadow-md rounded-md">
            <h3 className="text-xl font-bold mb-4">Traditional Approaches & Other Tools</h3>
            <ul className="list-disc list-outside ml-4 text-gray-600">
              <li>Time-consuming manual feedback</li>
              <li>Surface-level keyword analysis</li>
              <li>Generic improvement suggestions</li>
              <li>Less focus on individualized feedback</li>
            </ul>
          </div>
          <div className="p-4 shadow-md rounded-md">
            <h3 className="text-xl font-bold mb-4">Our AI-powered Tool</h3>
            <ul className="list-disc list-outside ml-4 text-gray-600">
              <li>Instant, automated feedback and scoring</li>
              <li>Detailed analysis and rewrites</li>
              <li>Quote-by-quote feedback</li>
              <li>Customized suggestions for improvement</li>
            </ul>
          </div>
        </div>

        <h3 className="text-blue-900 text-2xl sm:text-3xl text-center py-5 mt-5 uppercase">
          Testimonials
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <div className="bg-yellow-100 p-4 shadow-md rounded-md">
            <p className="text-gray-600 mb-4">
              "This AI tool helped me achieve my target band score. I scored an 8.5 in my IELTS writing!"
            </p>
            <p className="font-bold">- Li Wei, IELTS candidate</p>
          </div>
          <div className="bg-yellow-100 p-4 shadow-md rounded-md">
            <p className="text-gray-600 mb-4">
              "I was struggling with Task 2 essays, but this platform made my preparation so much easier. Highly recommended!"
            </p>
            <p className="font-bold">- Nguyen Thi Anh, IELTS candidate</p>
          </div>
        </div>

         <ScoreComparison />

        <h3 className="text-blue-900 text-2xl sm:text-3xl text-center py-5 mt-5 uppercase">
          Our Learning Approach
        </h3>

        <p className="text-gray-600 text-lg text-center mb-8">
          Our recommended learning approach focuses on taking numerous mock exams covering 30 topics for Writing Task 2. After each mock test, you will receive a score and detailed analysis, along with specific suggestions on how to improve based on the exact answer you provided.
        </p>
        <a
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 md:py-2 md:px-4 rounded-md text-xs md:text-base mb-16"
          href="/trainer"
        >
          Take IELTS mock test (free, no registration)
        </a>
        <Faq />
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
