export const articles = [
    {
        id: 1,
        title: 'What is a Good IELTS Score?',
        summary: 'In this article, we delve deeper into the IELTS writing scores, providing you with specific examples, statistics, and references to help you understand the nuances of the scoring system.',
        imgSrc: '/woman-with-questions.webp',
        link: '/articles/good-ielts-writing-score',
    },
    {
        id: 2,
        title: 'How is the IELTS Writing Task 2 Scored?',
        summary: 'IELTS Writing Task 2 is a crucial component of the IELTS exam, assessing your ability to compose an academic essay with effective grammar, vocabulary, and structure. This article delves into the scoring process.',
        imgSrc: '/complicated-math.webp',
        link: '/articles/writing-task-2-scoring',
    },
    {
        id: 3,
        title: 'New IELTS Writing Assessment Criteria (2023)',
        summary: 'Dive into the newly revealed IELTS Writing Assessment Criteria, understanding the standards used to grade your writing. Explore practical tips to utilize these criteria for improving your IELTS Writing skills.',
        imgSrc: '/ielts-writing-key-assessment-criteria.webp',
        link: '/articles/decoding-new-ielts-writing-criteria',
    },
    {
        id: 4,
        title: 'Top 10 Mistakes to Avoid in IELTS Writing Task 2: Illustrated with Examples',
        summary: 'Avoid these common mistakes and improve your IELTS Writing Task 2 performance. Get instant feedback, personalized suggestions, and practice with mock tests for Task 2, Academic, and General modules on our AI-powered education assistant.',
        imgSrc: '/stop.webp',
        link: '/ielts-writing-2-tips',
    },
    {
        id: 5,
        title: 'Mastering IELTS Writing Task 2: A Comprehensive Course',
        summary: 'Learn how to master the IELTS Writing Task 2 essay with our comprehensive course. From understanding the format to crafting compelling responses, this course covers all the essential elements for a top-scoring performance. Gain confidence and skills through practical tips, strategies, and practice tests.',
        imgSrc: '/girl-laptop.png',
        link: '/ielts-essay-course',
    },
    {
        id: 6,
        title: 'How Accurate is UpScore.ai for IELTS Essay Grading?',
        summary: 'Explore the accuracy and quality of UpScore.ai for grading IELTS essays. See how we benchmark against official IELTS grading and provide consistent and reliable results for IELTS preparation.',
        imgSrc: '/benchmark.svg',
        link: '/accuracy-and-quality',
    },
    {
        id: 7,
        title: 'IELTS Writing Task 2 Band Descriptors 2023: A Detailed Breakdown',
        summary: 'Discover the updated 2023 IELTS Writing Task 2 Band Descriptors in an accessible format. Understand how examiners evaluate your essay and learn what it takes to achieve your target band score. Ideal for non-native English speakers aiming to study abroad.',
        imgSrc: '/ielts-writing2-descriptors.png',
        link: '/articles/ielts-writing2-band-descriptors-2023',
    },
    {
        id: 8,
        title: 'Should I apply for IELTS Remark in writing?',
        summary: 'Does it make sense to request IELTS re-evaluation? How does IELTS scoring words internally? What is the typical deviation between different examiners? How much could bands be different?',
        imgSrc: '/re-marking-correlations.png',
        link: '/articles/ielts-remark-writing',
    },
    // More articles...
];